:root{
    --main-color: #ffc500;
}

/* margin and paddings */

.top_60{
  margin-top: 60px !important;
}

.top_30{
  margin-top: 30px !important;
}
.mb_20{
  margin-bottom: 20px !important;

}
.mb_30{
  margin-bottom: 30px !important;
}

.p_30{
  padding: 30px !important;
}

.pt_45{
  padding-top: 45px !important;
}
.pb_45{
  padding-bottom: 45px !important;
}

.p_50{
  padding: 50px !important;
}


@media(max-width:991px){

.top_60{
  margin-top: 30px !important;
}
}
/* end of padding  */
.container_shadow{
  box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.13);
  -webkit-box-shadow:0px 2px 92px 0px rgba(0,0,0,0.13);
  -moz-box-shadow:0px 2px 92px 0px rgba(0,0,0,0.13);
}

.main_content{
  background-color: #fff;
  min-height: 100vh;
  border-radius: 6px;
  -moz-border-radius:6px;
  -webkit-border-radius:6px;
  margin-bottom: 30px;
  
  
}
.section_title{
  position: relative;
  display:inline-block;
  padding: 0 3px;
}

.section_title > .section_title_text{
  font-size: 18px;
  font-weight: 600;
  position: relative;
  margin: 0;
}

.section_title span{
  position: absolute;
  height: 7px;
  width: 100%;
  bottom: 0;
  opacity: 0.4;
  left: 0;
  background-color: var(--main-color);
}

.section{
  padding-left: 30px;
  padding-right: 30px;

}
.graybg{
  background-color: #fafafa;
}