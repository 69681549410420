/* about me section */
.aboutme_text{
  color: darkslategray !important; 
  font: smaller !important;
  line-height: 22px !important;
  white-space: pre-line !important;
}

/* End of about me section */

/* TimeLine section */
.resume_timeline .timeline_content .timeline_title{
  font-size: 16px !important;
  font-weight: 500 !important;
  
}
.resume_timeline .timeline_content .timeline_date{
  color: darkslategray;
}

.resume_timeline .timeline_content .timeline_description{
  color: gray;
  font-size: small;
  margin-top: 6px;
  white-space: pre-line !important;
}



/* end of timeline section */

/* Services */

.service{
  height: 100%;
  text-align: center;
}

.service .service_title{
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 35px !important;
  text-align: center;
}

.service .service_description{
  color: #989898 !important;
  border-color: var(--main-color);
  margin-right: 5px !important;
  text-align: center;
}

.service_icon > .MuiSvgIcon-root{
  font-size: 46px !important;
  margin-left: 0 !important;
  font-weight: 300 !important;
  color: darkslategray !important;
}

/* end of Services */

/* Skills */
.skill{
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 48px 0px rgba(4,6,4,0.08) !important;
  min-height: 186px;
  height: 100%;
}

.skill .skill_title{
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 35px !important;

}

.skill .skill_description{
  display: flex;
  align-items: center;
  color: #989898 !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.skill .skill_description .timeline_dot{
  border-color: var(--main-color);
  padding: 2px !important;
  margin-right: 5px !important;
}

/* end of skills */

/* Contact section */
.contactInfo_Item{
  font-size: 14px !important;
  color: #787878;
  line-height: 34px !important;

}
.contactInfo_Item > span{
  color: black;
  font-weight: 500;
}

.contactInfo_socialsContainer{
  margin-top: 25px;
}

.contactInfo_social >a >.MuiSvgIcon-root{
  font-size: 16px !important;
  margin-right: 10px !important;
  color: black;
}
/* end of Contact  section*/